document.addEventListener('DOMContentLoaded', () => {
    'use strict'

    /*==================================
     START THE PAGE LOADER
    ==================================*/
    window.addEventListener("load", function () {
        const loader = document.querySelector(".loader-page");
        loader.className += " hidden";
    });


    particlesJS("particles-js", {
        particles: {
            number: { value: 160, density: { enable: true, value_area: 2052 } },
            color: { value: "#ffffff" },
            shape: {
                type: "circle",
                stroke: { width: 0, color: "#000000" },
                polygon: { nb_sides: 5 },
            },
            opacity: {
                value: 1,
                random: true,
                anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
            },
            size: {
                value: 1.5,
                random: true,
                anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
            },
            line_linked: {
                enable: false,
                distance: 150,
                color: "#ffffff",
                opacity: 0.1,
                width: 1
            },
            move: {
                enable: true,
                speed: 1,
                direction: "none",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 600 }
            }
        },
        interactivity: {
            detect_on: "canvas",
            events: {
                onhover: { enable: false, mode: "repulse" },
                onclick: { enable: true, mode: "repulse" },
                resize: true
            },
            modes: {
                grab: { distance: 400, line_linked: { opacity: 1 } },
                bubble: { distance: 250, size: 0, duration: 2, opacity: 0, speed: 3 },
                repulse: { distance: 400, duration: 0.4 },
                push: { particles_nb: 4 },
                remove: { particles_nb: 2 }
            }
        },
        retina_detect: true
    });

    AOS.init();


    /*==================================
     PLAY VIDEO
    ==================================*/
    //   var video = document.getElementById("videotts");
    //   video.play();

    const elements = document.querySelectorAll('.formatted-number');

    elements.forEach(element => {
        const number = parseFloat(element.textContent);
        const formattedNumber = number.toLocaleString();
        element.textContent = formattedNumber;
    });

});



